import { render, staticRenderFns } from "./CompanyLabel.vue?vue&type=template&id=104d194e&scoped=true"
import script from "./CompanyLabel.vue?vue&type=script&lang=js"
export * from "./CompanyLabel.vue?vue&type=script&lang=js"
import style0 from "./CompanyLabel.vue?vue&type=style&index=0&id=104d194e&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "104d194e",
  null
  
)

export default component.exports