import { render, staticRenderFns } from "./CompanyAddressTable.vue?vue&type=template&id=b2fb54ce&scoped=true"
import script from "./CompanyAddressTable.vue?vue&type=script&lang=js"
export * from "./CompanyAddressTable.vue?vue&type=script&lang=js"
import style0 from "./CompanyAddressTable.vue?vue&type=style&index=0&id=b2fb54ce&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2fb54ce",
  null
  
)

export default component.exports